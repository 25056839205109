// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/event';
// import router from "../../../router/routes";

const state = {
    // Additional state if needed
    eventsData: [],
    upcomingEventsData: [],
    eventByIdData: [],
}

const mutations = {
    SET_EVENTS_DATA(state, data) {
        state.eventsData = data
    },
    SET_UPCOMING_EVENTS(state, data) {
        state.upcomingEventsData = data
    },
    SET_EVENT_BY_ID_DATA(state, data) {
        state.eventByIdData = data
    },
}

const actions = {
    // Additional actions if needed

    getEvent({ commit }) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_EVENTS,{
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getEvent res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_EVENTS_DATA', res.data)
                        const currentDate = new Date();
                        const upcomingEvents = res.data.filter((event) => {
                          const eventDate = new Date(event.date);
                          return eventDate > currentDate;
                        });
                
                        commit('SET_UPCOMING_EVENTS', upcomingEvents);
                        console.log("upcomingEvents", upcomingEvents);
                      }
                      else{
                        commit('SET_EVENTS_DATA', res.data.message)
                      }
                  })
                  .catch((err) => {
                      console.log('getEvent err', err)
                  })
          // })
    },

    getEventById({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_EVENT_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getEventById res', res)
                      if(Array.isArray(res.data.data)){
                        commit('SET_EVENT_BY_ID_DATA', res.data.data[0])
                      }
                      else{
                        commit('SET_EVENT_BY_ID_DATA', res.data.data[0].message)
                      }
                  })
                  .catch((err) => {
                      console.log('getEventById err', err)
                  })
          // })
    },

    setTransaction({commit}, payload) {
      console.log("payload-setTransaction-", payload);
      // return
      var formData = new FormData();
      formData.append("persons", payload.persons);
      formData.append("event_id", payload.event_id);
      formData.append("user_id", payload.user_id);
      formData.append("price", payload.price);
      formData.append("transaction_id", payload.transaction_id);
      return new Promise((resolve, reject) => {
        axios
          .post(axios.defaults.baseURL + apis.SET_TRANSACTION, formData,{
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("setTransaction res", res);
            commit("UPDATE_LOADING_STATE", false);
            resolve(res);
          })
          .catch((err) => {
            console.log("setTransaction err", err);
            reject(err);
          });
      })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };