// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/profile';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    transactionData: [],
    myEventsId: [],
}

const mutations = {
    SET_TRANSACTION_DATA(state, data) {
        state.transactionData = data
    },
    SET__CHECK_MY_EVENTS(state, data) {
        state.myEventsId = data
    },
}

const actions = {
    // Additional actions if needed

    getTransactionByUserId({ commit }, user_id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_TRANSACTION + user_id,{
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getTransactionByUserId res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_TRANSACTION_DATA', res.data)
                      }
                      else{
                        commit('SET_TRANSACTION_DATA', res.data.message)
                      }

                      const myEvents = res.data.map((el)=>{
                        return parseInt(el.event_id)
                      })
                      console.log("my event id", myEvents)
                      commit('SET__CHECK_MY_EVENTS', myEvents)
                  })
                  .catch((err) => {
                      console.log('getTransactionByUserId err', err)
                  })
          // })
    },

    EditProfile({dispatch, commit}, payload) {
        console.log("payload-EditProfile-", payload);
        // return
        var formData = new FormData();
        formData.append("first_name", payload.first_name);
        formData.append("last_name", payload.last_name);
        formData.append("email", payload.email);
        formData.append("pseudo", payload.pseudo);
        formData.append("d_où_viens_tu", payload.d_où_viens_tu);
        formData.append("es_tu_installé_à_Bali", payload.es_tu_installé_à_Bali);
        formData.append("depuis_combien_de_temps", payload.depuis_combien_de_temps);
        formData.append("pour_combien_de_temps_seras_tu_à_Bali", payload.pour_combien_de_temps_seras_tu_à_Bali);
        formData.append("wpnumber", payload.wpnumber);
        formData.append("instagram", payload.instagram);
        formData.append("facebook", payload.facebook);
        formData.append("linkedin", payload.linkedin);
        formData.append("tes_meilleures_skills", payload.tes_meilleures_skills); // array of skills
        formData.append("ton_parcours", payload.ton_parcours);
        formData.append("tes_hobbies_à_bali", payload.tes_hobbies_à_bali);
        formData.append("profile_pic", payload.profile_pic);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.EDIT_PROFILE + payload.id, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("EditProfile res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("getLoginUserData")
              // dispatch("checkErrorAndSendToast", [
              //   res,
              //   "success",
              // ]);
              router.push("/profile")
            })
            .catch((err) => {
              console.log("EditProfile err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },


}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };