// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from "./apis/auth";
import router from "../router/routes";

const state = {
  // Additional state if needed
  user: null,
  token: null,
  isLoggedIn: false,
  componentLayout: 'UserLayout',
};

const mutations = {
  SET_LOGIN_DETAILS(state, data) {
    console.log("user set", data);
    state.user = data;
  },
  SET_USER_AUTHS(state, data) {
    console.log("SET_USER_AUTHS set", data);
    state.isLoggedIn = data.isLoggedIn;
    state.token = data.token;
  },
  SET_COMPONENT_LAYOUT(state, data) {
    console.log("SET_COMPONENT_LAYOUT set", data);
    state.componentLayout = data.layout;
  },

  // LOGOUT_USER(state, data) {
  //   state.user = data;
  //   state.token = data;
  // },
};

const actions = {
  // Additional actions if needed
  login({dispatch, commit }, payload) {
    console.log("payload--", payload);

    var formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", payload.password);

    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN, formData)
        .then((res) => {
          console.log("login res--", res);
          resolve(res)
          var formData2 = new FormData();
          formData2.append("token",  res.data.data.token);
          axios
            .post(axios.defaults.baseURL + apis.GET_CURRENT_LOGGED_IN_USER, formData2, {
              headers: {
                Authorization: "Bearer " + res.data.data.token,
              },
             }
            )
            .then((response) => {
              console.log("login details-- auth--", response);
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("user", JSON.stringify(response.data.data));
              
              if(response.data.data.roles === "0" || response.data.data.roles === 0){
                let user = {
                  created_at: response.data.data.user[0].created_at,
                  d_où_viens_tu: response.data.data.user[0].d_où_viens_tu,
                  depuis_combien_de_temps: response.data.data.user[0].depuis_combien_de_temps,
                  email: response.data.data.user[0].email,
                  email_verified_at: response.data.data.user[0].email_verified_at,
                  es_tu_installé_à_Bali: response.data.data.user[0].es_tu_installé_à_Bali,
                  first_name: response.data.data.user[0].first_name,
                  instagram: response.data.data.user[0].instagram,
                  last_name: response.data.data.user[0].last_name,
                  linkedin: response.data.data.user[0].linkedin,
                  name: response.data.data.user[0].name,
                  pour_combien_de_temps_seras_tu_à_Bali: response.data.data.user[0].pour_combien_de_temps_seras_tu_à_Bali,
                  profile_pic: response.data.data.user[0].profile_pic,
                  pseudo: response.data.data.user[0].pseudo,
                  roles: response.data.data.user[0].roles,
                  tes_hobbies_à_bali: response.data.data.user[0].tes_hobbies_à_bali,
                  tes_meilleures_skills: response.data.data.user[0].tes_meilleures_skills,
                  facebook: response.data.data.user[0].facebook,
                  ton_parcours: response.data.data.user[0].ton_parcours,
                  updated_at: response.data.data.user[0].updated_at,
                  user_id: response.data.data.user[0].user_id,
                  wpnumber: response.data.data.user[0].wpnumber,
                  token: res.data.data.token,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.data.token});
                commit("SET_COMPONENT_LAYOUT", { layout: "UserLayout" });
              }

                if (response.data.data.user[0].roles === "0" || response.data.data.user[0].roles === 0) {
                  router.push("/").then(() => {
                      router.go();
                  });
                }
                // router.push("/").go()
                // dispatch("checkErrorAndSendToast", [
                //   res,
                //   "success",
                // ]);
            })
            .catch((err) => {
              console.log("error ff hai--", err);
  
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              // router.go();
            });
        })
        .catch((err) => {
          console.log("login err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err)
        });
    })
  },


  getLoginUserData({dispatch,commit}){
    var token = localStorage.getItem("token")
    var formData2 = new FormData();
          formData2.append("token",  token);
          axios
            .post(axios.defaults.baseURL + apis.GET_CURRENT_LOGGED_IN_USER, formData2, {
              headers: {
                Authorization: "Bearer " + token,
              },
             }
            )
            .then((response) => {
              console.log("login details-- auth--", response);
              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(response.data.data));
              
              if(response.data.data.roles === "0" || response.data.data.roles === 0){
                let user = {
                  created_at: response.data.data.user[0].created_at,
                  d_où_viens_tu: response.data.data.user[0].d_où_viens_tu,
                  depuis_combien_de_temps: response.data.data.user[0].depuis_combien_de_temps,
                  email: response.data.data.user[0].email,
                  email_verified_at: response.data.data.user[0].email_verified_at,
                  es_tu_installé_à_Bali: response.data.data.user[0].es_tu_installé_à_Bali,
                  first_name: response.data.data.user[0].first_name,
                  instagram: response.data.data.user[0].instagram,
                  last_name: response.data.data.user[0].last_name,
                  linkedin: response.data.data.user[0].linkedin,
                  name: response.data.data.user[0].name,
                  pour_combien_de_temps_seras_tu_à_Bali: response.data.data.user[0].pour_combien_de_temps_seras_tu_à_Bali,
                  profile_pic: response.data.data.user[0].profile_pic,
                  pseudo: response.data.data.user[0].pseudo,
                  roles: response.data.data.user[0].roles,
                  tes_hobbies_à_bali: response.data.data.user[0].tes_hobbies_à_bali,
                  tes_meilleures_skills: response.data.data.user[0].tes_meilleures_skills,
                  facebook: response.data.data.user[0].facebook,
                  ton_parcours: response.data.data.user[0].ton_parcours,
                  updated_at: response.data.data.user[0].updated_at,
                  user_id: response.data.data.user[0].user_id,
                  wpnumber: response.data.data.user[0].wpnumber,
                  token: token,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
                commit("SET_COMPONENT_LAYOUT", { layout: "UserLayout" });
              }
                // router.push("/").go()
                // dispatch("checkErrorAndSendToast", [
                //   res,
                //   "success",
                // ]);
            })
            .catch((err) => {
              console.log("error ff hai--", err);
  
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              // router.go();
            });
  },


  userRegister({dispatch, commit}, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("first_name", payload.first_name == undefined ? "" : payload.first_name);
    formData.append("last_name", payload.last_name == undefined ? "" : payload.last_name);
    formData.append("email", payload.email == undefined ? "" : payload.email);
    formData.append("password", payload.password == undefined ? "" : payload.password);
    formData.append("pseudo", payload.pseudo == undefined ? "" : payload.pseudo);
    formData.append("d_où_viens_tu", payload.d_où_viens_tu == undefined ? "" : payload.d_où_viens_tu);
    formData.append("es_tu_installé_à_Bali", payload.es_tu_installé_à_Bali == undefined ? "" : payload.es_tu_installé_à_Bali);
    formData.append("depuis_combien_de_temps", payload.depuis_combien_de_temps == undefined ? "" : payload.depuis_combien_de_temps);
    formData.append("pour_combien_de_temps_seras_tu_à_Bali", 'Testing');
    // formData.append("pour_combien_de_temps_seras_tu_à_Bali", payload.pour_combien_de_temps_seras_tu_à_Bali == undefined ? "" : payload.pour_combien_de_temps_seras_tu_à_Bali);
    formData.append("wpnumber", payload.wpnumber == undefined ? "" : payload.wpnumber);
    formData.append("instagram", payload.instagram == undefined ? "" : payload.instagram);
    formData.append("facebook", payload.facebook == undefined ? "" : payload.facebook);
    formData.append("linkedin", payload.linkedin == undefined ? "" : payload.linkedin);
    formData.append("tes_meilleures_skills", payload.tes_meilleures_skills == undefined ? "" : payload.tes_meilleures_skills); // array of skills
    formData.append("ton_parcours", payload.ton_parcours == undefined ? "" : payload.ton_parcours);
    formData.append("tes_hobbies_à_bali", payload.tes_hobbies_à_bali == undefined ? "" : payload.tes_hobbies_à_bali);
    formData.append("profile_pic", payload.profile_pic == undefined ? "" : payload.profile_pic);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.REGISTER, formData)
        .then((res) => {
          console.log("userRegister res", res);
          localStorage.setItem("token", res.data.data.token);
          dispatch("getLoginUserData")
          router.push("/")
          // .then(() => {
          //   router.go();
          // });

          resolve(res);
          // dispatch("checkErrorAndSendToast", [
          //   res,
          //   "success",
          // ]);
        })
        .catch((err) => {
          console.log("userRegister err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          // dispatch("checkErrorAndSendToast", [
          //   err.response,
          //   "error",
          // ]);
        });
    })
  },


  logout({ commit }, payload) {

    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("token", payload.userToken);
      axios
        .post(axios.defaults.baseURL + apis.LOGOUT, formData, {
          headers: {
            Authorization: "Bearer " + payload.userToken,
          },
         })
        .then((res) => {
          resolve(res);

          console.log("logout res", res);
          commit("UPDATE_LOADING_STATE", false);

          localStorage.removeItem("token");
          localStorage.removeItem("user");

          if(payload.name == 'user'){
            router.push("/login").then(() => {
              router.go();
            });
          }
          // dispatch("checkErrorAndSendToast", [
          //   res,
          //   "success",
          // ]);
        })
        .catch((err) => {
          reject(err);
          console.log("logout err", err);
          // dispatch("checkErrorAndSendToast", [
          //   err.response,
          //   "error",
          // ]);
        });
    })
},


  forgotPassword({dispatch }, payload) {
    var formData = new FormData();
    formData.append("email", payload.email);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.FORGOT_PASSWORD, formData)
        .then((res) => {
          console.log("forgotPassword res", res);
          console.log("dispatch", dispatch);

          resolve(res);
          // dispatch("checkErrorAndSendToast", [
          //   res,
          //   "success",
          // ]);
        })
        .catch((err) => {
          console.log("forgotPassword err", err);
          // dispatch("checkErrorAndSendToast", [
          //   err.response,
          //   "error",
          // ]);
          reject(err);
        });
    })
  },

  resetPassword({dispatch }, payload) {
    console.log(payload, "payload")
    var formData = new FormData();
    formData.append("id", payload.id);
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.RESET_PASSWORD, formData)
        .then((res) => {
          console.log("resetPassword res", res);
          console.log("dispatch", dispatch);
          router.push("/login").then(() => {
            router.go();
          });

          resolve(res);
          // dispatch("checkErrorAndSendToast", [
          //   res,
          //   "success",
          // ]);
        })
        .catch((err) => {
          console.log("resetPassword err", err);
          // dispatch("checkErrorAndSendToast", [
          //   err.response,
          //   "error",
          // ]);
          reject(err);
        });
    })
  },
};

const getters = {
  // Additional getters if needed
  auth(state){
    return state.isLoggedIn
  },
  Layout(state){
    return state.componentLayout
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
